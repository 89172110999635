import React, {Component, useContext, useEffect} from 'react';

// Elements
import SliderOneSection from "../elements/home-slider/slider1";
import AboutUsOneSection from "../elements/about-us-1";
import ServicesSection from "../elements/services-1";
import ChooseUsSection from "../elements/choose-us";
import ScheduleServiceSection from "../elements/schedule-service";
import TeamOneSection from "../elements/team-1";
import TestimonialSection from "../elements/testimonial";
import LatestBlogSection from "../elements/latest-blog-slider";
import useTraceId from './../../hooks/useTraceId';
import { CompanyContext } from '../../contexts/CompanyContext';
import { LeadContext } from './../../contexts/LeadContext';
import { Helmet } from "react-helmet";
import Stories from '../elements/Stories';

// class Index extends Component{
	
	
// 	render(){

const Index = () => {
		
	const {companyData} = useContext(CompanyContext);
  const { traceData } = useContext(LeadContext);
	const trace = useTraceId();

  useEffect(() => {
    if(companyData.id) {
      if (trace.traceId) {
        trace.setTraceParams({
          ...traceData,
          trid: trace.traceId,
          crm_lead_id: trace.leadId,
          dominio: `${window.location.origin}`,
          local: `${window.location.pathname}`,
          title: `${document.title}`,
          tipo: null,
        });
      }
    }
  }, [trace.traceId, trace.leadId, companyData]);
		
	return (
			<>
				<Helmet>
					<title>{`${companyData?.seo_title ? companyData.seo_title : companyData.nome}${companyData?.slogan ? ` - ${companyData.slogan}` : ""}`}</title>
					<meta name="description" content={`${companyData?.seo_description}`}/>
					<meta name="og:description" content={`${companyData?.seo_description}`}/>
					<meta property="og:title" content={`${companyData?.seo_title ? companyData.seo_title : companyData.nome}${companyData?.slogan ? ` - ${companyData.slogan}` : ""}`}/>
					<meta property="og:site_name" content={`${companyData?.seo_title ? companyData.seo_title : companyData.nome}${companyData?.slogan ? ` - ${companyData.slogan}` : ""}`}/>
					<meta property="og:url" content={`${window.location.host}${window.location.pathname}`}/>
					<meta property="og:image" content={`${companyData?.logo}`}/>
					<meta name="keywords" content={companyData?.seo_tags}/>
    		</Helmet>
			
				<SliderOneSection />

				<Stories/>
				
				<AboutUsOneSection />
				
				<ServicesSection />
				
				<ChooseUsSection />
				
				<ScheduleServiceSection />
				
				{/* <TeamOneSection /> */}
				
				<TestimonialSection />
				
				<LatestBlogSection />
				
			</>
		);
	}
// }

export default Index;