import React, { Component, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Import Images
import testPic1 from "../../images/testimonials/pic1.jpg"
import testPic2 from "../../images/testimonials/pic2.jpg"
import testPic3 from "../../images/testimonials/pic3.jpg"
import testPic4 from "../../images/testimonials/pic4.jpg"
import testPic5 from "../../images/testimonials/pic5.jpg"
import testPic6 from "../../images/testimonials/pic6.jpg"
import blogGridPic1 from "../../images/blog/grid/pic1.jpg"
import blogGridPic2 from "../../images/blog/grid/pic2.jpg"
import blogGridPic3 from "../../images/blog/grid/pic3.jpg"
import blogGridPic4 from "../../images/blog/grid/pic4.jpg"
import blogGridPic5 from "../../images/blog/grid/pic5.jpg"
import blogGridPic6 from "../../images/blog/grid/pic6.jpg"
import { CompanyContext } from '../../contexts/CompanyContext';
import { getNews } from './../../services/news/requests';
import { dateFormatterLong } from './../../utils/convertDates';

// Team Content
const content = [
	{ 
		postMedia: blogGridPic1,
		postAuthor: testPic1,
		postAuthorName: "Isabella Evans",
		postDate: "9 May 2021",
		postTitle: "The Number Of Electric Vehicles Doubled",		
		postText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",		
	},
	{ 
		postMedia: blogGridPic2,
		postAuthor: testPic2,
		postAuthorName: "George Brown",
		postDate: "8 May 2021",
		postTitle: "Make Your Auto Clean As Before Like New",		
		postText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",		
	},
	{ 
		postMedia: blogGridPic3,
		postAuthor: testPic3,
		postAuthorName: "Thomas deo",
		postDate: "7 May 2021",
		postTitle: "Interior Cleaning with a Steam Generator",		
		postText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",		
	},
	{ 
		postMedia: blogGridPic4,
		postAuthor: testPic4,
		postAuthorName: "Isabella Evans",
		postDate: "6 May 2021",
		postTitle: "The Number Of Electric Vehicles Doubled",		
		postText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",		
	},
	{ 
		postMedia: blogGridPic5,
		postAuthor: testPic5,
		postAuthorName: "George Brown",
		postDate: "5 May 2021",
		postTitle: "Make Your Auto Clean As Before Like New",		
		postText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",		
	},
	{ 
		postMedia: blogGridPic6,
		postAuthor: testPic6,
		postAuthorName: "Thomas deo",
		postDate: "4 May 2021",
		postTitle: "Interior Cleaning with a Steam Generator",		
		postText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",		
	}
]

// class LatestBlogSection extends Component{
// 	render(){

	const LatestBlogSection = () => {

		const {companyData} = useContext(CompanyContext);
		const [news, setNews] = useState([]);

		useEffect(() => {
			if(companyData.id) {
				getNewsList()
			}
		},[companyData])

		const getNewsList = async () => {
			const result = await getNews({}, companyData.id)
			if(result.data.metadata.result >= 1){
				setNews(result.data.data.filter(item => item.status === 'active'))
			}
		}
		
		const settings = {
			dots: false,
			infinite: true,
			speed: 1200,
			slidesToShow: news.length < 3 ? news.length : 3,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2500,
			responsive: [
				{
					breakpoint: 1191,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		
		return(
			<>
				{news?.length > 0 ? 
					<section className="section-area section-sp2 bg-gray ov-hidden">
						<div className="container">
							<div className="heading-bx text-center">
								<h6 className="title-ext text-primary">Mais Recentes</h6>
								<h2 className="title mb-0">Artigos e Notícias</h2>
								{/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or slightly believable.</p> */}
							</div>
							<Slider {...settings} className="service-slide slick-arrow-none">
								{news.map((item, index) =>( <>
									<div key={index} className="slider-item">								
										<div className="blog-card style-1 bg-white shadow">
											{item.media?.length > 0 ? 
												<div className="post-media">
													<Link href="blog-details.html"><img src={item.media[0].file} alt=""/></Link>
												</div>
											: <></>}
											<div className="post-info">
												<h5 className="post-title"><Link to="/blog-details">{item.title}</Link></h5>
												{/* <div className="post-content">
													<p className="mb-0">{item.postText}</p>
												</div> */}
												<ul className="post-meta">
													{/* <li className="author"><img src={item.postAuthor} alt=""/>By <Link to="/blog-details">{item.postAuthorName}</Link></li> */}
													<li className="date"><Link to="/blog-details">{item.created_at ? dateFormatterLong.format(new Date(item.created_at)) : ""}</Link></li>
												</ul>
											</div>
										</div>
									</div>
								</>
								))}
							</Slider>
						</div>
					</section>
				: <></>}
				
			</>
		);
	}
// }

export default LatestBlogSection;