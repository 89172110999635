import React, {Component, useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

// Images
import bnrImg from "../../images/banner/bnr2.jpg";
import car1 from "../../images/why-choose/car-1.png";
import car2 from "../../images/why-choose/car-2.png";
import car3 from "../../images/why-choose/car-3.png";
import car4 from "../../images/why-choose/car-4.png";
import car5 from "../../images/why-choose/car-5.png";
import car6 from "../../images/why-choose/car-6.png";
import { CompanyContext } from '../../contexts/CompanyContext';
import { WhatsAppContext } from '../../contexts/WhatsappContext';
import { LeadContext } from '../../contexts/LeadContext';
import useTraceId from '../../hooks/useTraceId';
import { Helmet } from 'react-helmet';
import { getPagesBySlug } from '../../services/pages/requests';
import { createLead } from './../../services/lead/requests';
import { LeadData } from './../../contexts/leadData';
import Swal from 'sweetalert2';
import { phoneMask } from './../../utils/maskForPhone';
import formatWhatsNumber from '../../utils/formatWhatsNumber';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';

// class ContactUs2 extends Component{
	
// 	render(){
	const ContactUs2 = () => {

		const {companyData} = useContext(CompanyContext);
		const { changeData } = useContext(WhatsAppContext);
		const [ pageBanner, setPageBanner] = useState("");
		const [ title, setTitle] = useState("");
		const [loading, setLoading] = useState(false);
		const [data, setData] = useState({nome: "", email: "", celular: "", mensagem: ""})

		const { traceData } = useContext(LeadContext);
		const trace = useTraceId();

		const [pageTitle, setPageTitle] = useState("");
		const [lastPageTitle, setLastPageTitle] = useState(document.title);

		useEffect(() => {
			setPageTitle(document.title);
		}, [document.title]);

		useEffect(() => {
			if(companyData.id && pageTitle !== lastPageTitle) {
				if (trace.traceId) {
					trace.setTraceParams({
						...traceData,
						trid: trace.traceId,
						crm_lead_id: trace.leadId,
						dominio: `${window.location.origin}`,
						local: `${window.location.pathname}`,
						title: `${document.title}`,
						tipo: null,
					});
				}
			}
		}, [trace.traceId, trace.leadId, companyData, pageTitle]);

		useEffect(() => {
			if(companyData.id) {

				changeData({
					phone: companyData?.whatsapp_number,
					message: `Olá, ${companyData?.nome}!. Estou acessando seu site, ${window.location.host}, na tela de ${title ? title : "Contato"}. Alguém poderia me atender?`
				})

				const getPage = async () => {
					// console.log(pathname)
					const pathnameWithoutSlash = window.location.pathname.substring(1);
					const result = await getPagesBySlug(pathnameWithoutSlash, {company: companyData.id})
					// console.log(result)
					if(result?.data?.metadata?.result >= 1) {
						// console.log(result.data)
						setTitle(result?.data?.data[0]?.title)
						setPageBanner(result?.data?.data[0]?.media[0]?.file || "")
					} 
				}
				getPage()
			}

			if(localStorage.getItem('___LeadID')){
				setData({nome: localStorage.getItem('___LeadName'), email: localStorage.getItem('___LeadEmail'), celular: localStorage.getItem('___LeadMobile')})
			}
		},[companyData])

		const buildRequestData = () => {
			const url = window.location.href;
	
			const lead = new LeadData({
				email: data.email,
				nome: data.nome,
				celular: data.celular ? data.celular.replace(/[^0-9]/g, '') : "",
				cidade: companyData?.id_city,
				id_company: companyData?.id,
				estado: companyData?.id_uf,
				tipo: 3,
				source: 'contato',
				additional_Information: `${data.assunto ? `${data.assunto}: ` : ""}${data.mensagem ? data.mensagem : ""}`,
				dominio: window.location.host,
				local: window.location.pathname,
				referrer: window.location.href,
				agent: navigator.userAgent,
				// ...traceData,
				trace_id: trace.traceId,
				url,
			});
			
			return lead.getData();
		};
	
		const sendLead = async (e) => {
			e.preventDefault();
	
			if(data.nome && data.celular) {
				setLoading(true)
				const leadData = buildRequestData();
				// console.log(leadData)
				const result = await createLead(leadData);
				const leadId = result?.data?.data?.lead_id || null;
				if (leadId) {
					localStorage.setItem('___LeadID', leadId);
					localStorage.setItem('___LeadName', leadData.nome);
					localStorage.setItem('___LeadMobile', leadData.celular);
					localStorage.setItem('___LeadEmail', leadData.email);
					Swal.fire({
						icon: 'success',
						title: `Obrigado pelo contato ${leadData.nome}!`,
						text: `Em breve entraremos em contato, muito obrigado!`,
					});
					setData({...data, assunto: "", mensagem: ""})
				} else {
					Swal.fire({
						icon: 'error',
						title: result.data.metadata.output.raw,
						text: result.data.metadata.reason,
					});
				}
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Nome e celular não podem ser vazios',
					// text: 'Nome e Celular não podem ser vazios',
				});
			}
			setLoading(false)
		};

		return (
			<>
				<Helmet>
					<title>{`${title ? title : "Contato"}`} </title>
					<meta name="description" content={`${title ? `${title} - ` : 'Contato - '} ${companyData?.seo_title ? companyData.seo_title : companyData.nome}`}/>
					<meta name="og:description" content={`${title ? `${title} - ` : 'Contato - '} ${companyData?.seo_title ? companyData.seo_title : companyData.nome}`}/>
					<meta property="og:title" content={`${title ? title : "Contato"}`}/>
					<meta property="og:site_name" content={`${title ? title : "Contato"}`}/>
					<meta property="og:url" content={`${window.location.host}${window.location.pathname}`}/>
					<meta property="og:image" content={`${companyData?.logo}`}/>
					<meta name="keywords" content={companyData?.seo_tags}/>
				</Helmet>
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: pageBanner ? `url(${pageBanner})` : ""}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>{title ? title : "Contato"}</span></h1>
								{/* <nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Contact Us 2</li>
									</ul>
								</nav> */}
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-5 mb-50">
									<form className="contact-form style1 ajax-form" /*action="script/contact.php"*/ onSubmit={(e) => sendLead(e)}>
										<div className="heading-bx mb-4 text-white">
											<h6 className="title-ext text-white">Formulário Contato</h6>
											<h3 className="title mb-0">Fale Conosco</h3>
										</div>
										<div className="row">
											<div className="col-lg-12">
												<div className="form-group  mb-3">
													<div className="input-group">
														<input name="name" type="text" className="form-control valid-character" placeholder="ex: Amanda Martins" value={data.nome} onChange={(e) => setData({...data, nome: e.target.value})}/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group  mb-3">
													<div className="input-group"> 
														<input name="email" type="email" className="form-control"  placeholder="ex: exemplo@email.com" value={data.email} onChange={(e) => setData({...data, email: e.target.value})}/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group mb-3">
													<div className="input-group">
														<input name="phone" type="tel" className="form-control int-value" placeholder="ex: (99) 99999-9999" value={phoneMask(data.celular)} onChange={(e) => setData({...data, celular: e.target.value})}/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group mb-3">
													<div className="input-group">
														<textarea name="message" rows="4" className="form-control" placeholder="Digite sua mensagem..." value={data.mensagem} onChange={(e) => setData({...data, mensagem: e.target.value})}></textarea>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<button name="submit" type="submit" value="Submit" className="btn btn-secondary btn-lg"> 
													{loading ? 
														<div className="spinner-grow" role="status" style={{height: 10, width: 10, marginRight: 8}}></div>
													: <></>}
													{loading ? 'Enviando' : 'Enviar Mensagem'}
												</button>
											</div>
										</div>
									</form>
								</div>
								<div className="col-lg-7 mb-30">
									<div className="contact-ri">
										<div className="heading-bx mb-4">
											<h6 className="title-ext text-primary">Informações de Contato</h6>
											<h2 className="title mb-0">FALE CONOSCO PARA QUALQUER <br/>INFORMAÇÃO</h2>
										</div>
										<div className="row">
											<div className="col-md-6 col-sm-6 mb-15">
												<div className="icon-box">
													<h6 className="title"><i className="ti-map-alt"></i>Localização</h6>		
													<p>
														{companyData?.endereco && companyData?.number
															? `
																${companyData?.endereco || ''}${
																	companyData?.endereco && companyData?.number
																		? `, ${companyData?.number}`
																		: ''
																}
																${companyData?.dsitrict || ''}${
																	companyData?.endereco && companyData?.district
																	? `, ${companyData?.district}`
																	: ''
																}
																${
																	companyData?.endereco &&
																	companyData?.city &&
																	companyData?.uf
																		? ` - ${companyData?.city}/${companyData?.uf}`
																		: ''
																}
															`
															: companyData?.city && companyData?.uf ? `${companyData?.city}/${companyData?.uf}`:''
														}
													</p>
												</div>
											</div>
											<div className="col-md-6 col-sm-6 mb-15">
												<div className="icon-box">
													<h6 className="title"><i className="ti-id-badge"></i>E-mail e Telefone/Celualr</h6>		
													<Link target="_blank" to={`mailto:${companyData?.email}`} rel="noreferrer" style={{color: '#777777'}}>
														{companyData?.email}
													</Link> 
													<br/>
													<p>
														{companyData?.whatsapp_number && 
															<Link 
																target="_blank" 
																to={`https://api.whatsapp.com/send?phone=55${formatWhatsNumber(companyData?.whatsapp_number)}&text=Olá, ${companyData?.nome}! Acessei o seu site ${window.location.host} e preciso de ajuda, alguém pode me atender? `} 
																rel="noreferrer" 
																style={{color: '#777777'}}
															>
																<i className="fa fa-whatsapp" style={{marginRight: 8}}></i>
																{formatPhoneNumber(companyData?.whatsapp_number)}
															</Link>
														}
														<br/>
														{companyData?.phone_main && 
															<Link target="_blank" to={`tel:${formatWhatsNumber(companyData?.phone_main)}`} rel="noreferrer" style={{color: '#777777'}}>
																{formatPhoneNumber(companyData?.phone_main)}
															</Link>
														}
													</p>
												</div>
											</div>
											{companyData?.facebook || companyData?.instagram || companyData?.linkedin ? 
												<div className="col-lg-12 mb-15">
													<div className="icon-box">
														<h6 className="title"><i className="ti-world"></i>Siga-nos</h6>
														<ul className="social-media">
															{companyData?.facebook && 
																<li><a target="_blank" href={companyData?.facebook} rel="noreferrer" className="btn btn-primary"><i className="fa fa-facebook"></i></a></li>
															}
															{companyData?.instagram && 
																<li><a target="_blank" href={companyData?.instagram} rel="noreferrer" className="btn btn-primary"><i className="fa fa-instagram"></i></a></li>
															}
															{companyData?.linkedin && 
																<li><a target="_blank" href={companyData?.linkedin} rel="noreferrer" className="btn btn-primary"><i className="fa fa-linkedin"></i></a></li>
															}
															{/* <li><a target="_blank" href="https://www.google.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-google-plus"></i></a></li> */}
															{/* <li><a target="_blank" href="https://twitter.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-twitter"></i></a></li> */}
														</ul>
													</div>
												</div>
											: <></>}
										</div>
										<ul className="brand-list">
											<li><img src={car1} alt=""/></li>
											<li><img src={car2} alt=""/></li>
											<li><img src={car3} alt=""/></li>
											<li><img src={car4} alt=""/></li>
											<li><img src={car5} alt=""/></li>
											<li><img src={car6} alt=""/></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</section>
		
				</div>
				
			</>
		);
	}
// }

export default ContactUs2;