import React, {Component, useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

// Elements
import AboutUsTwoSection from "../elements/about-us-2";
import ServicesSection from "../elements/services-1";
import ScheduleServiceSection from "../elements/schedule-service";
import PricingTableSection from "../elements/pricing-table";
import TestimonialSection from "../elements/testimonial";
import LatestBlogSection from "../elements/latest-blog-slider";

// Images
import bnrImg from "../../images/banner/bnr1.jpg";
import { CompanyContext } from '../../contexts/CompanyContext';
import { LeadContext } from './../../contexts/LeadContext';
import useTraceId from './../../hooks/useTraceId';
import { getPagesBySlug } from '../../services/pages/requests';
import { Helmet } from 'react-helmet';

// class AboutUs extends Component{
	
// 	render(){
const DynamicPage = () => {

	const [page, setPage] = useState({});
  const {companyData} = useContext(CompanyContext);
  const { traceData } = useContext(LeadContext);
  const trace = useTraceId();

  const [pageTitle, setPageTitle] = useState("");
  const [lastPageTitle, setLastPageTitle] = useState(document.title);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    if(companyData){
      const getPage = async () => {
        // console.log(pathname)
        const pathnameWithoutSlash = window.location.pathname.substring(1);
        const result = await getPagesBySlug(pathnameWithoutSlash, {company: companyData.id})
        if(result.data.metadata.result >= 1) {
          // console.log(result.data)
          setPage(result.data.data[0])
        } 
      }
      getPage()
    }
  },[companyData])

  useEffect(() => {
    setPageTitle(document.title);
  }, [document.title]);

  useEffect(() => {
    if(companyData.id && page.id && page.title && pageTitle !== lastPageTitle) {
      if (trace.traceId) {
        trace.setTraceParams({
          ...traceData,
          trid: trace.traceId,
          crm_lead_id: trace.leadId,
          dominio: `${window.location.origin}`,
          local: `${window.location.pathname}`,
          title: `${document.title}`,
          tipo: null,
        });
      }
    }
  }, [trace.traceId, trace.leadId, companyData, pageTitle]);

	return (page ? 
		<>
			<Helmet>
        <title>{`${page?.seo_title ? page.seo_title : page.title}`}</title>
        <meta name="description" content={`${page?.seo_description ? page?.seo_description : companyData?.seo_description}`}/>
        <meta name="og:description" content={`${page?.seo_description ? page?.seo_description : companyData?.seo_description}`}/>
        <meta property="og:title" content={`${page ? page?.seo_title ? page.seo_title : page.title : ""}`}/>
        <meta property="og:site_name" content={`${page ? page?.seo_title ? page.seo_title : page.title : ""}`}/>
        <meta property="og:url" content={`${window.location.host}${window.location.pathname}`}/>
        <meta property="og:image" content={`${companyData?.logo}`}/>
      	<meta name="keywords" content={page?.seo_tags ? page?.seo_tags : companyData.seo_tags}/>
      </Helmet>
			
			<div className="page-content bg-white">
				
				<div className="page-banner ovbl-dark" style={{backgroundImage: page?.media?.length > 0 ? `url(${page.media[0].file})` : ""}}>
					<div className="container">
						<div className="page-banner-entry text-center">
							<h1><span>{page?.title}</span></h1>
							{/* <nav aria-label="breadcrumb" className="breadcrumb-row">
								<ul className="breadcrumb">
									<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
									<li className="breadcrumb-item active" aria-current="page">About Us 1</li>
								</ul>
							</nav> */}
						</div>
					</div>
				</div>

				<section className="section-area section-sp1 bg-white" >
					<div className="container">
						<div dangerouslySetInnerHTML={{__html: page?.description}}></div>
					</div>
				</section>
				
				{/* <AboutUsTwoSection /> */}
				
				{/* <ServicesSection /> */}
				
				{/* <ScheduleServiceSection /> */}
				
				{/* <PricingTableSection /> */}
				
				{/* <TestimonialSection /> */}
				
				{/* <LatestBlogSection /> */}
			
			</div>
			
		</>
		: <></>
	);
}
// }

export default DynamicPage;