import { useContext } from 'react';
import formatWhatsNumber from '../../../utils/formatWhatsNumber';
import { WhatsAppContext } from '../../../contexts/WhatsappContext';


const WhatsAppFloatButton = () => {
  const {whatsAppInfo} = useContext(WhatsAppContext);

  return (
    <a
      style={{
        position: 'fixed',
        bottom: 90,
        right: 30,
        fontSize: 30,
        borderRadius: 50,
        background: '#25D366',
        width: 50,
        height: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        cursor: 'pointer',
        boxShadow: '0 0 5px rgba(0, 0, 0, .4)',
        zIndex: 999,
      }}
      href={`https://api.whatsapp.com/send?phone=55${formatWhatsNumber(whatsAppInfo.phone)}&text=${whatsAppInfo.message}`}
      target="_blank"
      rel="noreferrer"
    >
      <i className="fa fa-whatsapp"></i>
    </a>
  );
};

export default WhatsAppFloatButton;
