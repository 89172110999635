import React, {Component, useContext, useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import { Accordion } from "react-bootstrap";

// Images
import bnrImg from "../../images/banner/bnr1.jpg";
import team3 from "../../images/team/team3.jpg";
import { CompanyContext } from './../../contexts/CompanyContext';
import { WhatsAppContext } from './../../contexts/WhatsappContext';
import useTraceId from './../../hooks/useTraceId';
import { LeadContext } from './../../contexts/LeadContext';
import { getSpecificJob } from '../../services/jobs/requests';
import { Helmet } from 'react-helmet';
import { LeadData } from './../../contexts/leadData';
import { createLead } from './../../services/lead/requests';
import Swal from 'sweetalert2';
import { postFiles } from '../../services/files/requests';
import { useDropzone } from "react-dropzone";
import InputMask from "react-input-mask";

import SitePro from '../../images/site-pro.png';
import { currencyFormatter } from './../../utils/currencyFormatter';
import { formatPhoneNumber } from './../../utils/formatPhoneNumber';
import { cnhOption, educationOptions } from '../../utils/mockDataJob';
import { phoneMask } from './../../utils/maskForPhone';

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  marginLeft: 5,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  // border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: "auto",
  height: 90,
  // padding: 4,
  boxSizing: "border-box",
};

// class JobDetails extends Component{
	
// 	render(){
const JobDetails = () => {

	const { slug } = useParams();

  const [job, setJob] = useState(null);
  const {companyData} = useContext(CompanyContext);
  const { changeData } = useContext(WhatsAppContext);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({nome: "", email: "", cpf: "", celular: ""})
	const [currentFiles, setCurrentFiles] = useState([]);
  const trace = useTraceId();
  const { traceData } = useContext(LeadContext);

  const [pageTitle, setPageTitle] = useState("");
  const [lastPageTitle, setLastPageTitle] = useState(document.title);

  useEffect(() => {
    setPageTitle(document.title);
  }, [document.title]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    if(slug) {
      // console.log(slug)
      getJob()
    }
  },[slug])

  useEffect(() => {
    if(job?.id && companyData?.id) {
      changeData({
        phone: companyData?.whatsapp_number,
        message: `Olá, ${companyData?.nome}!. Estou acessando seu site, ${window.location.host} Gostaria de saber mais sobre a vaga ${job.title}.`
      })
    }
		if(localStorage.getItem('___LeadID')){
      setData({nome: localStorage.getItem('___LeadName'), email: localStorage.getItem('___LeadEmail'), celular: localStorage.getItem('___LeadMobile')})
    }
  },[job, companyData])

  useEffect(() => {
    if(job?.id && pageTitle !== lastPageTitle) {
      if (trace.traceId) {
        trace.setTraceParams({
          ...traceData,
          trid: trace.traceId,
          crm_lead_id: trace.leadId,
          dominio: `${window.location.origin}`,
          local: `${window.location.pathname}`,
          title: `${document.title}`,
          tipo: null,
        });
      }
    }
  }, [trace.traceId, trace.leadId, pageTitle]);

  const getJob = async () => {
    // console.log(slug)
    const result = await getSpecificJob(slug)
    // console.log(result)
    if(result.data.metadata.result >= 1) {
      setJob(result.data.data[0])
    } 
  }

	const buildRequestData = () => {
    const url = window.location.href;

    const lead = new LeadData({
      ...data,
      tipo: 5,
      celular: data.celular ? data.celular.replace(/[^0-9]/g, '') : "",
      additional_Information: `id: ${job.id} - title: ${job.title} - link: ${window.location.href}`,
      cpf: data.cpf ? data.cpf.replace(/[^0-9]/g, '') : "",
      cidade: companyData?.id_city,
      id_company: companyData?.id,
      estado: companyData?.id_uf,
      source: 'candidato',
      dominio: window.location.host,
      local: window.location.pathname,
      referrer: window.location.href,
      agent: navigator.userAgent,
      // ...traceData,
      trace_id: trace.traceId,
      url,
    });
    
    return lead.getData();
  };

  const getFirstName = (frase) => {
    // Remova os espaços em branco no início e no final da frase
    frase = frase.trim();
    // Divida a frase em palavras usando o espaço como delimitador
    var palavras = frase.split(" ");
    // Pegue o primeiro elemento do array de palavras
    var primeiraPalavra = palavras[0];
    // Retorne a primeira palavra
    return primeiraPalavra;
  }

  const sendLead = async (e) => {
    e.preventDefault();

    if(data.nome && data.celular) {
      setLoading(true)
  
      const leadData = buildRequestData();
      // console.log(leadData)
  
      const result = await createLead(leadData);
      const leadId = result?.data?.data?.lead_id || null;
      if (leadId) {
  
        const formData = new FormData();
        formData.append('files[]', currentFiles[0], currentFiles[0].name);
        formData.append('slug', job.title);
        formData.append('type', 'lead_files ');
        formData.append('id', leadId);
  
        const resultFile = await postFiles(formData)
        if(resultFile.data.metadata.result >= 1) {
          setCurrentFiles([])
        }
  
        localStorage.setItem('___LeadID', leadId);
        localStorage.setItem('___LeadName', leadData.nome);
        localStorage.setItem('___LeadMobile', leadData.celular);
        localStorage.setItem('___LeadEmail', leadData.email);
  
        Swal.fire({
          icon: 'success',
          title: `${getFirstName(leadData.nome)}, enviamos sua candidatura! 🚀`,
          text: `Em breve entraremos em contato sobre a vaga! 🎉💼`,
        });

				setData({...data, cpf: ""})
  
      } else {
        Swal.fire({
          icon: 'error',
          title: result.data.metadata.output.raw,
          text: result.data.metadata.reason,
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Nome e celular não podem ser vazios',
        // text: 'Nome e Celular não podem ser vazios',
      });
    }
    setLoading(false)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: "application/pdf",
    multiple: false,
    onDrop: (acceptedFiles) => {
      setCurrentFiles([
        ...currentFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            progress: 0,
          })
        ),
      ]);
    },
  });

	useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      currentFiles?.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

	const thumbs = currentFiles?.map((file, index) => {
    return (
      <div style={thumb} key={`${file.name}-${index}`}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <i className='fa fa-file-pdf-o' style={{fontSize: 50, color: 'red'}}/>
					<span>{file.name}</span>
        </div>
        {/* {file.progress > 0 && file.progress < 100 && (
          <div>
            <progress value={file.progress} max="100" />
          </div>
        )} */}
      </div>
    );
  });

	const scrollToCategory = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

	const removeAll = () => {
    setCurrentFiles([])
  }

	return (
		<>
			<Helmet>
        <title>{`${`${job?.seo_title ? job.seo_title : job?.title}`}`}</title>
        <meta name="description" content={`${companyData?.seo_title ? companyData.seo_title : companyData.nome} oferece a vaga ${job?.seo_title ? job.seo_title : job?.title}`}/>
        <meta name="og:description" content={`${companyData?.seo_title ? companyData.seo_title : companyData.nome} oferece a vaga ${job?.seo_title ? job.seo_title : job?.title}`}/>
        <meta property="og:title" content={`${job?.seo_title ? job.seo_title : job?.title}`}/>
        <meta property="og:site_name" content={`${job?.seo_title ? job.seo_title : job?.title}`}/>
        <meta property="og:url" content={`${window.location.host}${window.location.pathname}`}/>
        <meta property="og:image" content={`${companyData?.logo ? companyData?.logo : `${window.location.host}${SitePro}`}`}/>
        <meta name="keywords" content={job?.seo_tags ? job?.seo_tags : companyData?.seo_tags}/>
      </Helmet>
			<div className="page-content bg-white">
				
				<div className="page-banner ovbl-dark" style={{backgroundImage: ""}}>
					<div className="container">
						<div className="page-banner-entry text-center">
							<h1><span>{job?.title}</span></h1>
							{/* <nav aria-label="breadcrumb" className="breadcrumb-row">
								<ul className="breadcrumb">
									<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
									<li className="breadcrumb-item active" aria-current="page">Team Details</li>
								</ul>
							</nav> */}
						</div>
					</div>
				</div>
				
				<section className="section-area section-sp1 bg-white">
					<div className="container">
						<div className="row member-detail">
							<div className="col-lg-4 col-md-5 mb-30">
								<div className="sticky-top">
									<div className="team-box">
										{/* <div className="team-media">
											<img src={team3} alt=""/>
										</div> */}
										<div className="team-info">
											<span className="text-primary">{job?.title}</span>
											<h4 className="title">{job?.salary ? currencyFormatter.format(Number(job.salary)) : "R$ Consultar"}<a style={{fontSize: 14, marginLeft: 10, textDecoration: 'underline'}} href={'#candidatar'} onClick={() => scrollToCategory('candidatar')}>Candidatar-se</a></h4>
											<ul className="icon-list">
												<li><i className="fa fa-phone"></i>{formatPhoneNumber(job?.phone ? job.phone : companyData?.whatsapp_number ? companyData.whatsapp_number : companyData.phone_main)}</li>
												<li><i className="fa fa-envelope-o"></i>{companyData?.email}</li>
											</ul>
											{/* <ul className="social-media">
												<li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/" className="btn btn-primary"><i className="fa fa-facebook"></i></a></li>
												<li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/" className="btn btn-primary"><i className="fa fa-instagram"></i></a></li>
												<li><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/" className="btn btn-primary"><i className="fa fa-linkedin"></i></a></li>
												<li><a target="_blank" rel="noreferrer" href="https://twitter.com/" className="btn btn-primary"><i className="fa fa-twitter"></i></a></li>
											</ul> */}
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-8 col-md-7 mb-30">
								{/* <h3 className="title">Michel H. Bohman</h3>
								<p className="mb-30">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. Synthetic engine oils are produced through a synthesis process that takes very small molecules and assembles them into larger designer molecules.</p> */}
								
								<h5 className="title">Requisitos:</h5>
								{/* <p className="mb-30">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words.</p> */}
								<ul className="list-check-squer mb-50">
									{job?.experience_required ? 
										<li>
											<i className='fa fa-briefcase'  /> Experiência: {job.experience_required ? 'Sim' : "Não"}
										</li>
									: <></>}
									{job?.education_level ? 
										<li>
											<i className='fa fa-graduation-cap' /> Grau Instrução: {(educationOptions.find(type => type.value == job.education_level)).label}
										</li>
									: <></>}
									{job?.driver_licence ?
										<li>
											<i className='fa fa-id-card-o' /> Carteira de Motorista: {(cnhOption.find(type => type.value == job.driver_licence)).label}
										</li>
									: <></>}
									{job?.needs_travelling ?
										<li>
											<i className='fa fa-plane' /> Viagens: {job.needs_travelling ? 'Sim' : 'Não'}
										</li>
									: <></>}
									{job?.fluency ? 
										<li>
											<i className='fa fa-commenting-o'  /> Fluência: {job.fluency}
										</li>
									: <></>}
									{/* <li>Should I consider using synthetic motor oil?</li>
									<li>How do I keep track of routine maintenance?</li>
									<li>What parts should be replaced at what intervals?</li>
									<li>How do I keep track of routine maintenance?</li> */}
								</ul>
								
								{/* <h5 className="title">Skills:</h5>
								<p className="mb-30">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words.</p>
								<div className="progress-area">
									<div className="progress-box style1 appear">
										<div className="progress-info">
											<h6 className="title">Quality Services</h6>
											<h6 className="progress-value">65%</h6>
										</div>
										<div className="progress">
											<div className="progress-bar bg-primary" style={{width: "65%"}}></div>
										</div>
									</div>
									<div className="progress-box style1 appear">
										<div className="progress-info">
											<h6 className="title">Experienced Technicials</h6>
											<h6 className="progress-value">85%</h6>
										</div>
										<div className="progress">
											<div className="progress-bar bg-primary" style={{width: "85%"}}></div>
										</div>
									</div>
									<div className="progress-box style1 appear">
										<div className="progress-info">
											<h6 className="title">Long Term Warranty</h6>
											<h6 className="progress-value">70%</h6>
										</div>
										<div className="progress">
											<div className="progress-bar bg-primary" style={{width: "70%"}}></div>
										</div>
									</div>
								</div> */}
								
								<h5 className="title">Detalhes:</h5>
								<p className="mb-30">{job?.details}</p>

								<h5 className="title">Benefícios:</h5>
								<p className="mb-30">{job?.benefits}</p>
								
								{/* <Accordion defaultActiveKey="0" className="accordion ttr-accordion style1">
									<Accordion.Item eventKey="0">
										<Accordion.Header>
											<i className="fa fa-question-circle"></i>
											Should I consider using synthetic motor oil?
										</Accordion.Header>
										<Accordion.Body>
											<p className="mb-0">Synthetic engine oils are produced through a synthesis process that takes very small molecules and assembles them into larger designer molecules with premium lubricating properties.</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1">
										<Accordion.Header>
											<i className="fa fa-question-circle"></i>
											What parts should be replaced at what intervals?
										</Accordion.Header>
										<Accordion.Body>
											<p className="mb-0">Synthetic engine oils are produced through a synthesis process that takes very small molecules and assembles them into larger designer molecules with premium lubricating properties.</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="2">
										<Accordion.Header>
											<i className="fa fa-question-circle"></i>
											How do I keep track of routine maintenance?
										</Accordion.Header>
										<Accordion.Body>
											<p className="mb-0">Synthetic engine oils are produced through a synthesis process that takes very small molecules and assembles them into larger designer molecules with premium lubricating properties.</p>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>									 */}
							</div>
						</div>
					</div>
				</section>


				<section className="section-area mb-70" id='candidatar'>
					<div className="container">		
						<div className="row">
							<div className="col-lg-12 col-md-12">
								<form className="contact-form ajax-form" /*action="script/contact.php"*/ onSubmit={(e) => sendLead(e)}>
									<div className="heading-bx mb-20">
										<h6 className="title-ext text-primary">Formulário de Candidatura</h6>
										<h2 className="title mb-0">Candidate-se Agora</h2>
									</div>
									<div className="row">
										<div className="col-lg-6 col-md-6">
											<div className="form-group">
												<div className="input-group">
													<input name="name" type="text" className="form-control valid-character" placeholder="ex: Amanda Martins" value={data.nome} onChange={(e) => setData({...data, nome: e.target.value})}/>
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-md-6">
											<div className="form-group">
												<div className="input-group"> 
													<input name="email" type="email" className="form-control"  placeholder="ex: exemplo@email.com" value={data.email} onChange={(e) => setData({...data, email: e.target.value})}/>
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-md-6">
											<div className="form-group">
												<div className="input-group">
													<input name="phone" type="tel" className="form-control int-value" placeholder="ex: (99) 99999-9999" value={phoneMask(data.celular)} onChange={(e) => setData({...data, celular: e.target.value})}/>
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-md-6">
											<div className="form-group">
												<div className="input-group">
													<InputMask
                            mask="999.999.999-99"
                            placeholder="CPF"
														className="form-control int-value"
                            value={data.cpf}
                            onChange={(e) => setData({...data, cpf: e.target.value})}
                          >
                            {(inputProps) => (
                              <input
                                {...inputProps}
                                type="text"
                                style={{ width: '100%' }}
  
                              />
                            )}
                          </InputMask>
													{/* <input name="phone" type="text" required className="form-control int-value" placeholder="ex: "/> */}
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-md-6">
											<div
												style={{
													textAlign: "center",
													// padding: 3,
													border: "1px grey dashed",
													width: "100%",
													maxWidth: "100%",
													marginBottom: 8
												}}
												{...getRootProps({ className: "dropzone" })}
											>
												<section>
													<div className="mt-3">
														<input {...getInputProps()} />
														{currentFiles.length > 0 ? 
															<p></p>	
														: 
															isDragActive ? (<>
																<p>Solte o arquivo aqui...</p>
															</>) : (<>
																	<p>Arraste e solte o arquivo...</p>
																</>
															)
														}
													</div>
													<aside style={thumbsContainer}>{thumbs}</aside>
												</section>
											</div>
											{currentFiles.length > 0 && <Link to="" onClick={removeAll}> <span className="mb-2 mt-2" style={{color: 'red'}}>Remover Arquivo</span></Link>}
										</div>
										<div className="col-lg-12" style={{display: 'flex', justifyContent: 'flex-end'}}>
											<button name="submit" type="submit" value="Submit" className="btn btn-primary btn-lg">
												{loading ? 
													<div className="spinner-grow" role="status" style={{height: 10, width: 10, marginRight: 8}}></div>
												: <></>}
												{loading ? 'Enviando' : 'Candidatar-se'}
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			
			</div>
			
		</>
	);
}
// }

export default JobDetails;