import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import ImageMaintenance from '../../images/maintenance.webp';
import { Helmet } from 'react-helmet';

// class Error404 extends Component{
	
// 	render(){
	const Maintenance = () => {
		return (
			<>
				<Helmet>
					<title>Manutenção</title>
				</Helmet>
				<div className="error-404">
					<div className="inner-content container">
						<img src={ImageMaintenance} alt="Manutenção"/>
						<h2>503</h2>
						<h3 className="">Espere! Estamos em Manutenção</h3>
						<p>A página que você está procurando pode ter sido removida, ter o nome alterado ou temporariamente inacessível.</p>
						{/* <div className="clearfix">
							<Link to="/" className="btn btn-primary m-r5">Back</Link>
							<Link to="/" className="btn btn-primary">Back To Home</Link>
						</div> */}
					</div>
				</div>
			</>
		);
	}
// }

export default Maintenance;