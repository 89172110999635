import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Import Images
import recentBlogImg1 from "../../../images/blog/recent-blog/pic1.jpg";
import recentBlogImg2 from "../../../images/blog/recent-blog/pic2.jpg";
import recentBlogImg3 from "../../../images/blog/recent-blog/pic3.jpg";
import { dateFormatterLong } from '../../../utils/convertDates';

// class WidgetRecentPosts extends Component{
// 	render(){
	const WidgetRecentPosts = ({news = []}) => {
		return(news?.length > 0 ?
			<>
				<div className="widget recent-posts-entry">
					<h5 className="widget-title">Mais Artigos</h5>
					<div className="widget-post-bx">
						{news.map(item => (
							<div className="widget-post clearfix" key={item.id}>
								{item?.media?.length > 0 ? 
									<Link to={`/artigo/${item.slug}`}>
										<div className="ttr-post-media"> <img src={item.media[0].file} width="200" height="143" alt=""/> </div>
									</Link>
								: <></>}
								<div className="ttr-post-info">
									<div className="ttr-post-header">
										<h6 className="post-title"><Link to={`/artigo/${item.slug}`}>{item.title}</Link></h6>
									</div>
									<ul className="post-meta">
										<li className="author"><Link href={`/artigo/${item.slug}`}>{item.created_at ? dateFormatterLong.format(new Date(item.created_at)) : ""}</Link></li>
									</ul>
								</div>
							</div>
						))}
						{/* <div className="widget-post clearfix">
							<div className="ttr-post-media"> <img src={recentBlogImg2} width="200" height="160" alt=""/> </div>
							<div className="ttr-post-info">
								<div className="ttr-post-header">
									<h6 className="post-title"><Link to="/blog-details">Ten Doubts You Should Clarify About.</Link></h6>
								</div>
								<ul className="post-meta">
									<li className="author">By <Link to="#">Merry Desulva</Link></li>
								</ul>
							</div>
						</div> */}
						{/* <div className="widget-post clearfix">
							<div className="ttr-post-media"> <img src={recentBlogImg3} width="200" height="160" alt=""/> </div>
							<div className="ttr-post-info">
								<div className="ttr-post-header">
									<h6 className="post-title"><Link to="/blog-details">The 10 Steps Needed For Putting.</Link></h6>
								</div>
								<ul className="post-meta">
									<li className="author">By <Link to="#"> Mark John</Link></li>
								</ul>
							</div>
						</div> */}
					</div>
				</div>
				
			</>
			: <></>
		);
	}
// }

export default WidgetRecentPosts;