import { Outlet, useNavigate } from "react-router-dom";

// Layout
import Header from "./layout/header";
import Footer from "./layout/footer";
import { useContext, useEffect, useState } from "react";
import { WhatsAppContext } from "./../contexts/WhatsappContext";
import { CompanyContext } from "../contexts/CompanyContext";
import { PageContext } from "./../contexts/PagesContext";
import {
  getCompanyByDomain,
  getCompanyById,
} from "./../services/company/requests";
import { getFavIcon } from "../services/files/requests";
import { getPages } from "./../services/pages/requests";
import DynamicStyle from "./elements/DynamicStyle/DynamicStyle";
import { getWalls } from "./../services/walls/requests";
import WhatsAppFloatButton from "./elements/WhatsappFloatButton";

export default function ThemeLayout() {
  const navigate = useNavigate();
  const { changeData } = useContext(WhatsAppContext);
  const { setCompany, companyData } = useContext(CompanyContext);
  const { setPages } = useContext(PageContext);
  const [stylesDynamic, setStylesDynamic] = useState(null);
  const [loading, setLoading] = useState(false);
  const [favIcon, setFavIcon] = useState("");

  const [wall, setWall] = useState();
  const [showWall, setShowWall] = useState(false);

  useEffect(() => {
    if (companyData.id) {
      changeData({
        phone: companyData?.whatsapp_number,
        message: `Olá, ${companyData?.nome}!. Vim do seu site ${window.location.host}. Alguém poderia me atender?`,
      });
    }
  }, [companyData]);

  useEffect(() => {
    if (favIcon) {
      const changeFavicon = (iconPath) => {
        const favicon = document.querySelector('link[rel="icon"]');
        if (favicon) {
          favicon.href = iconPath;
        }
      };

      const faviconPath = favIcon.file;

      changeFavicon(faviconPath);
    }
  }, [favIcon]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    if (window.location.host.includes("localhost:")) {
      getData(process.env.REACT_APP_DOMAIN);
    } else {
      getData(window.location.host);
    }
  }, []);

  const getData = async (domain) => {
    // console.log(domain)
    setLoading(true);
    const result = await getCompanyByDomain(domain);
    if (result.data.metadata.result >= 1) {
      // console.log(result.data.data)
      getCompanyData(result.data.data.id);
      getPagesList(result.data.data.id);
      getIcon(result.data.data.id);
      getWallsList(result.data.data.id);
      // result.data.data.theme_variables_index === 2 ? navigate('/index-2') : navigate('/')
    } else {
      navigate("/manutencao");
    }
    // console.log(result.data)
    setLoading(false);
  };

  const getIcon = async (id) => {
    const result = await getFavIcon(id);
    if (result) {
      if (result.data.metadata.result >= 1) {
        setFavIcon(result.data.data[0]);
      } else {
        setFavIcon(null);
      }
    }
  };

  const getCompanyData = async (id) => {
    setLoading(true);
    const result = await getCompanyById(id);
    if (result.data.metadata.result >= 1) {
      // console.log(result.data.data[0].styles)
      setStylesDynamic(result.data.data[0].styles);
      setCompany(result.data.data[0]);
    }
    setLoading(false);
    // console.log(result.data)
  };

  const getPagesList = async (id) => {
    const result = await getPages({}, id);
    if (result.data.metadata.result >= 1) {
      setPages(result.data.data);
      // console.log(result.data.data)
    }
    // console.log(result.data.data)
  };

  const getWallsList = async (id) => {
    const result = await getWalls({}, id);
    // console.log(result.data.data.data[0]);
    if (result) {
      if (result.data.metadata.result >= 1) {
        if (result.data.data[0].status === "active") {
          setWall(result.data.data[0]);
          setShowWall(true);
        }
      } else {
        setWall();
      }
    }
  };

  return (
    <>
      {stylesDynamic && <DynamicStyle css={stylesDynamic} />}
      {showWall && window.location.pathname === "/" || window.location.pathname === 'index-2' && wall ? (
        <div
          className={`alert`}
          style={{
            textAlign: "center",
            backgroundColor: `${wall?.bg_color ? wall.bg_color : "#EE9322"}`,
            color: "#FFF",
            cursor: "pointer",
            // padding: '0!important',
            // zIndex: 99,
            borderRadius: 5,
            border: "none",
            // position: 'fixed',
            width: "100%",
            paddingRight: "4rem",
            transition: " opacity 0.15s linear",
            display: "block !important",
            padding: 10,
            marginBottom: 0,
            // paddingBottom: "50!important"
          }}
        >
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setShowWall(false)}
            style={{
              zIndex: 99,
              position: "absolute",
              background: "none",
              border: "none",
              top: 0,
              right: 0,
              padding: "0.75rem 1.25rem",
              color: "inherit",
              float: "right",
              fontSize: "1.8rem",
              fontWeight: 700,
              lineHeight: 1,
              color: "#000",
              // textShadow: '0 1px 0 #fff',
              opacity: 0.5,
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <strong style={{ fontSize: 20 }}>
            {wall?.title} {" | "}{" "}
          </strong>
          <span style={{ fontSize: 15 }}>{wall?.description}</span>
          {wall?.button ? (
            <a
              href={`${wall?.link ? wall.link : ""}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                type="submit"
                style={{
                  textDecoration: "none",
                  // display:'block',
                  background:
                    "linear-gradient(180deg, #0a3940 -65%, #0279ab 110%)",
                  border: "none",
                  color: "white",
                  fontSize: 15,
                  fontWeight: 600,
                  padding: "4px 8px",
                  marginTop: 0,
                  marginBottom: 0,
                  borderRadius: 5,
                  marginLeft: 15,
                }}
              >
                {wall?.button}
              </button>
            </a>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      <Header wall={showWall}/>
      <Outlet />
			<WhatsAppFloatButton />
      <Footer />
    </>
  );
}
