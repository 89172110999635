import React, { Component, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Images
import logo2 from '../../images/logo2.png';
import icon1 from '../../images/icon/contact/icon1.png';
import icon2 from '../../images/icon/contact/icon2.png';
import icon3 from '../../images/icon/contact/icon3.png';
import recentBlogPic1 from '../../images/blog/recent-blog/pic1.jpg';
import recentBlogPic2 from '../../images/blog/recent-blog/pic2.jpg';
import { CompanyContext } from '../../contexts/CompanyContext';
import { PageContext } from './../../contexts/PagesContext';
import { getLogoFooter } from '../../services/files/requests';
import { getNews } from './../../services/news/requests';
import { getJobs } from './../../services/jobs/requests';
import { LeadData } from './../../contexts/leadData';
import useTraceId from './../../hooks/useTraceId';
import { createLead } from './../../services/lead/requests';
import Swal from 'sweetalert2';
import { formatarCEP } from './../../utils/formatCep';
import formatWhatsNumber from './../../utils/formatWhatsNumber';
import { formatPhoneNumber } from './../../utils/formatPhoneNumber';
import { dateFormatterLong } from './../../utils/convertDates';
import { phoneMask } from './../../utils/maskForPhone';
import { formatarCNPJ } from './../../utils/formatDocuments';

import SitePro from '../../images/site-pro.png';

// class aboutSection extends Component{
// 	render(){
	const AboutSection = () => {

		const {companyData} = useContext(CompanyContext);
		const {pagesData} = useContext(PageContext);
		const currentDate = new Date();
		const [news, setNews] = useState([]);
		const [jobs, setJobs] = useState([]);
		const [logoFooter, setLogoFooter] = useState(null);
		const [whatsapp, setWhatsapp] = useState("");
		const trace = useTraceId();
		const [loading, setLoading] = useState(false);

		useEffect(() => {
			if(companyData.id) {
				getLogo()
				getNewsList()
				getJobsList()
			}
			if(localStorage.getItem('___LeadID')){
				setWhatsapp(localStorage.getItem('___LeadMobile'))
			}
		},[companyData])

		const getLogo = async () => {
			const result = await getLogoFooter(companyData.id)
			if (result) {
				if (result.data.metadata.result >= 1) {
					setLogoFooter(result.data.data[0]);
				} else {
					setLogoFooter(null)
				}
			}
		};
	
		const getNewsList = async () => {
			const result = await getNews({}, companyData.id)
			if(result.data.metadata.result >= 1){
				setNews(result.data.data.filter(item => item.status === 'active'))
			}
		}
	
		const getJobsList = async () => {
			const result = await getJobs({}, companyData.id)
			if(result.data.metadata.result >= 1){
				setJobs(result.data.data.filter(item => item.status === 'active'))
			}
		}

		const buildRequestData = () => {
			const url = window.location.href;
	
			const lead = new LeadData({
				nome: localStorage.getItem('___LeadName') ? localStorage.getItem('___LeadName') : "",
				celular: whatsapp ? whatsapp.replace(/[^0-9]/g, '') : "",
				cidade: companyData?.id_city,
				id_company: companyData?.id,
				estado: companyData?.id_uf,
				tipo: 1,
				source: 'newsletter',
				dominio: window.location.host,
				local: window.location.pathname,
				referrer: window.location.href,
				agent: navigator.userAgent,
				// ...traceData,
				trace_id: trace.traceId,
				url,
			});
			
			return lead.getData();
		};
	
		const getFirstName = (frase) => {
			// Remova os espaços em branco no início e no final da frase
			frase = frase.trim();
			// Divida a frase em palavras usando o espaço como delimitador
			var palavras = frase.split(" ");
			// Pegue o primeiro elemento do array de palavras
			var primeiraPalavra = palavras[0];
			// Retorne a primeira palavra
			return primeiraPalavra;
		}
	
		const sendLead = async (e) => {
			e.preventDefault();
	
			if(whatsapp) {
				setLoading(true)
		
				const leadData = buildRequestData();
				const result = await createLead(leadData);
				const leadId = result?.data?.data?.lead_id || null;
				if (leadId) {
					localStorage.setItem('___LeadID', leadId);
					localStorage.setItem('___LeadMobile', leadData.celular);
					Swal.fire({
						icon: 'success',
						title: `Obrigado por se cadastrar ${leadData.nome ? `, ${getFirstName(leadData.nome)}` : ""}! 🎉`,
						text: `Agora você está conectado com as últimas novidades! 📱✨`,
					});
				} else {
					Swal.fire({
						icon: 'error',
						title: result.data.metadata.output.raw,
						text: result.data.metadata.reason,
					});
				}
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Celular não pode ser vazio',
					// text: 'Nome e Celular não podem ser vazios',
				});
			}
	
			setLoading(false)
		};

		// `https://maps.google.com/maps?q=${companyData?.endereco ? companyData?.endereco : ''}${companyData?.number ? `,${companyData?.number}` : ""}${companyData?.district ? `,${companyData?.district}` : ""}${companyData?.city ? `,${companyData?.city}` : ""}${companyData?.uf ? `,${companyData?.uf}` : ""}&output=embed`

		return(
			<>
				<footer className="footer">
					<div className="footer-info bg-primary">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-3 col-md-6 col-sm-6 mb-30">
									<div className="footer-logo">
										<img src={logoFooter ? logoFooter.file : SitePro} alt="" className='logo_footer'/> 
									</div>
								</div>
								{companyData?.endereco && companyData?.number ? 
									<div className="col-lg-3 col-md-6 col-sm-6 mb-30">
										<div className="feature-container left footer-info-bx">
											<a target="_blank" href={`https://maps.google.com/maps?q=${companyData?.endereco ? companyData?.endereco : ''}${companyData?.number ? `,${companyData?.number}` : ""}${companyData?.district ? `,${companyData?.district}` : ""}${companyData?.city ? `,${companyData?.city}` : ""}${companyData?.uf ? `,${companyData?.uf}` : ""}`} rel="noreferrer">
												<div className="icon-lg">
													<span className="icon-cell"><img src={icon3} alt=""/></span> 
												</div>
												<div className="icon-content">
													<p>
														{companyData?.endereco && companyData?.number
														? `
																${companyData?.endereco || ''}${
																companyData?.endereco && companyData?.number
																	? `, ${companyData?.number}`
																	: ''
															}
																${companyData?.dsitrict || ''}${
																companyData?.endereco && companyData?.district
																	? `, ${companyData?.district}`
																	: ''
															}
																${
																	companyData?.endereco &&
																	companyData?.city &&
																	companyData?.uf
																		? ` - ${companyData?.city}/${companyData?.uf}`
																		: ''
																}
															`
														: companyData?.city && companyData?.uf
														? `${companyData?.city}/${companyData?.uf}`
														: ''}
														{/* {companyData?.cep ? ` - ${formatarCEP(companyData.cep)}` : ""} */}
													</p>
												</div>
											</a>
										</div>
									</div>
								: <></>}
								{companyData?.whatsapp_number || companyData?.phone_main ?
									<div className="col-lg-3 col-md-6 col-sm-6 mb-30">
										<div className="feature-container left footer-info-bx">
											<div className="icon-lg">
												<span className="icon-cell"><img src={icon1} alt=""/></span> 
											</div>
											<div className="icon-content">
												<p>
													{companyData?.whatsapp_number && 
														<a target="_blank" href={`https://api.whatsapp.com/send?phone=55${formatWhatsNumber(companyData?.whatsapp_number)}&text=Olá, ${companyData?.nome}! Acessei o seu site ${window.location.host} e preciso de ajuda, alguém pode me atender? `} rel="noreferrer" className='text-white'>
															{formatPhoneNumber(companyData?.whatsapp_number)}
															<i className="fa fa-whatsapp" style={{marginLeft: 8}}></i>
														</a>
													}
													<br/>
													{companyData?.phone_main && 
														<a target="_blank" href={`tel:${formatWhatsNumber(companyData?.phone_main)}`} rel="noreferrer" className='text-white'>
															{formatPhoneNumber(companyData?.phone_main)}
														</a>
													}
												</p>
											</div>
										</div>
									</div>
								: <></>}
								{companyData?.email && 
									<div className="col-lg-3 col-md-6 col-sm-6 mb-30">
										<div className="feature-container left footer-info-bx">
											<div className="icon-lg">
												<span className="icon-cell"><img src={icon2} alt=""/></span> 
											</div>
											<div className="icon-content">
												<p>
													<a target="_blank" href={`mailto:${companyData?.email}`} rel="noreferrer" className='text-white'>
														{companyData.email}
													</a> 
													{/* <br/>example@support.com */}
												</p>
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</div>
					<div className="footer-top bt0">
						<div className="container">
							<div className="row">
								<div className="col-xl-3 col-md-6">
									<div className="widget footer_widget">
										<h5 className="footer-title">{companyData?.nome}</h5>
										<p className="mb-20">{companyData?.description}</p>
										<div className="ft-content">
											<i className="fa fa-phone"></i>
											<span>Fale Conosco</span>
											<h4>
												<a 
													target="_blank" 
													href={
														companyData?.whatsapp_number ? 
														`https://api.whatsapp.com/send?phone=55${formatWhatsNumber(companyData?.whatsapp_number)}&text=Olá, ${companyData?.nome}! Acessei o seu site ${window.location.host} e preciso de ajuda, alguém pode me atender? `
														: `tel:${formatWhatsNumber(companyData?.phone_main)}`
													} 
													rel="noreferrer"
													className='text-white'
												>
													{
														companyData?.whatsapp_number ? 
															formatPhoneNumber(companyData?.whatsapp_number)
														: formatPhoneNumber(companyData?.phone_main)
													}
												</a>
											</h4>
										</div>
									</div>
								</div>
								{pagesData.length > 0 || jobs.length > 0 ?
									<div className="col-xl-3 col-md-6">
										<div className="widget widget-link-2">
											<h5 className="footer-title">Acessos Rápidos</h5>
											<ul>
												{pagesData.filter(item => item.show_in == 'both' || item.show_in == 'footer').map((page) => (
														<li key={page.id}><Link to={page.slug}>{page.title}</Link></li>
													))
												}
												{jobs.length > 0 ? 
													<li><Link to="/vagas">Trabalhe Conosco</Link></li> 
												: <></>}
												{/* <li><Link to="/about-us">About Us</Link></li>
												<li><Link to="#">Engine Services</Link></li>
												<li><Link to="/booking">Booking</Link></li>
												<li><Link to="#">Oil And Filters</Link></li>
												<li><Link to="/services">Our Services</Link></li>
												<li><Link to="#">Belts And Hoses</Link></li>
												<li><Link to="/pricing-plan">Pricing Plan</Link></li>
												<li><Link to="#">Brake Repair</Link></li>
												<li><Link to="/team">Out Team</Link></li>
												<li><Link to="#">Air Conditioning</Link></li>
												<li><Link to="/contact-us">Contact Us</Link></li>
												<li><Link to="#">Tire And Wheel</Link></li> */}
											</ul>							
										</div>
									</div>
								: <></>}
								{news.length > 0 ? 
									<div className="col-xl-3 col-md-6">
										<div className="widget recent-posts-entry">
											<h5 className="footer-title">Notícias e Artigos</h5>
											<div className="widget-post-bx">
												{news.slice(0,3).map(item => (
													<div className="widget-post clearfix">
														{item.media.length > 0 ?
															<div className="ttr-post-media"> 
																<img src={item.media[0].file} alt=""/> 
															</div>
														: <></>}
														<div className="ttr-post-info">
															<h6 className="post-title"><Link to="/blog-details">{item.title}</Link></h6>
															<ul className="post-meta">
																<li className="date"><Link to="/blog-details"><i className="fa fa-calendar"></i> {dateFormatterLong.format(new Date(item.created_at))}</Link></li>
															</ul>
														</div>
													</div>
												))}
												{/* <div className="widget-post clearfix">
													<div className="ttr-post-media"> 
														<img src={recentBlogPic2} alt=""/> 
													</div>
													<div className="ttr-post-info">
														<h6 className="post-title"><Link to="/blog-details">Ten Doubts You Should Clarify About.</Link></h6>
														<ul className="post-meta">
															<li className="date"><Link to="/blog-details"><i className="fa fa-calendar"></i> 15 Aug 2021</Link></li>
														</ul>
													</div>
												</div> */}
											</div>
										</div>
									</div>
								: <></>}
								<div className="col-xl-3 col-md-6">
									<div className="widget widget_info">
										<h5 className="footer-title">Receba Novidades</h5>
										{/* <p className="mb-20">Weekly Breaking News Analysis And Cutting Edge Advices On Job Searching.</p> */}
										<form className="subscribe-form subscription-form mb-30" onSubmit={(e) => sendLead(e)}>
											<div className="ajax-message"></div>
											<div className="input-group">
												<input name="tel" required="required" className="form-control" placeholder="ex: (99) 99999-9999" type="tel" value={phoneMask(whatsapp)} onChange={(e) => setWhatsapp(e.target.value)}/>
												<div className="input-group-append">
													<button name="submit" value="Submit" type="submit" className="btn btn-block btn-primary radius-sm">
														{loading ? 
															<div className="spinner-grow" role="status" style={{height: 10, width: 10, marginRight: 8}}></div>
														: <></>}
														{loading ? 'Enviando' : 'Enviar'}
													</button>	
												</div>
											</div>
										</form>
										{companyData?.facebook || companyData?.instagram || companyData?.linkedin ? 
											<ul className="list-inline ft-social-bx">
												{companyData?.facebook ? 
													<li><a target="_blank" rel="noreferrer" href={companyData?.facebook} className="btn button-sm"><i className="fa fa-facebook"></i></a></li>
												: <></>}
												{companyData?.instagram ? 
													<li><a target="_blank" rel="noreferrer" href={companyData?.instagram} className="btn button-sm"><i className="fa fa-instagram"></i></a></li>
												: <></>}
												{companyData?.linkedin ? 
													<li><a target="_blank" rel="noreferrer" href={companyData?.linkedin} className="btn button-sm"><i className="fa fa-linkedin"></i></a></li>
												: <></>}
												{/* <li><a target="_blank" rel="noreferrer" href="https://twitter.com/" className="btn button-sm"><i className="fa fa-twitter"></i></a></li> */}
											</ul>
										: <></>}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-bottom">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 col-md-12 col-sm-12 text-center text-md-start">
									<a href={`${process.env.REACT_APP_FOOTER_LINK}${window.location.host}`} target="_blank" rel="noopener noreferrer">
										<div style={{display: 'flex', flexDirection:'column', alignItems: 'center'}}>
											<p className="mb-0">&copy; {currentDate.getFullYear()} {companyData?.social_name} - Todos os direitos reservados.</p>
											{companyData?.cnpj ? 
                      	<p className="copyright">Inscrito sob o CNPJ - {formatarCNPJ(companyData.cnpj)}</p>
                  		: <></>}
											<img className="logo_product" src={SitePro} style={{height: 50}}/>
										</div>
									</a>
								</div>
								{/* <div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-end">
									<ul className="widget-link">
										<li><Link to="/index">Home</Link></li>
										<li><Link to="/about-us">About</Link></li>
										<li><Link to="/faq-1">FAQs</Link></li>
										<li><Link to="/services">Services</Link></li>
										<li><Link to="/contact-us">Contact</Link></li>
									</ul>
								</div> */}
							</div>
						</div>
					</div>
				</footer>
			
			</>
		);
	}
// }

export default AboutSection;