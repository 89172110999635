import ReactInstaStories, { WithSeeMore } from 'react-insta-stories';
import { useEffect, useState } from 'react';
import { getFileType, getUniqueMediaFile } from '../../../utils/imagesStories';
import { dateFormatterPeriod } from './../../../utils/convertDates';

const makeInstaStories = (stories, company) => {
  if(stories) {
    // console.log(company)
    const instaStories = stories?.map(story => ({
      url: getUniqueMediaFile(story?.media)?.file,
      header: {
        heading: company?.nome ?? process.env.CLIENT_NAME,
        subheading: `Postado ${dateFormatterPeriod.format(new Date(story?.created_at))} atrás`,
        profileImage: company.logo || '../img/sem-foto.jpg',
      },
      type: getFileType(story?.media),
      seeMoreCollapsed: ({ toggleMore, action }) => (
        story.link ? 
        <p
          style={{
            textAlign: 'center',
            fontSize: 14,
            bottom: 20,
            position: 'relative',
            cursor: 'pointer',
          }}
          onClick={() =>
            window.open(story.link, '_blank')
          }
          // onClick={() => toggleMore(true)}
        >
          Ver mais →
        </p>
        : <></>
      ),
      seeMore: ({ close }) => (
        story.link ? 
        <div
          style={{
            maxWidth: '100%',
            height: '100%',
            padding: 40,
            background: 'white',
          }}
        >
          <a href={story.link}>
            Clique aqui para ser direcionado para o site.
          </a>
          <p style={{ textDecoration: 'underline' }} onClick={close}>
            Fechar
          </p>
        </div>
        : <></>
      ),
    }));
    return instaStories;
  }
  return []

};

const ModalStories = ({stories, company, onClose, open}) => {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if(open) {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768); // Defina 768 como o limite para dispositivos móveis
      };
  
      handleResize(); // Verifique o tamanho da tela no carregamento inicial
      window.addEventListener('resize', handleResize); // Adicione um ouvinte de redimensionamento da janela
  
      return () => {
        window.removeEventListener('resize', handleResize); // Remova o ouvinte de redimensionamento quando o componente for desmontado
      };
    }
  }, [open]);

  return open ? (
    <div 
      style={
        {
          top: 0,
          position: 'fixed',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: '999',
        }
      } 
      onClick={onClose}
    >
      <div
        style={{
          borderRadius: 20,
          // backgroundColor: 'white',
          position: 'relative',
          width: '300px',
          borderRadius: '10px',
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/* <CloseBtn onClick={onClose}>X</CloseBtn> */}
        <ReactInstaStories
          loop
          keyboardNavigation={true}
          stories={makeInstaStories(stories, company)}
          defaultInterval={3000}
          width={isMobile ? '100%' : 432}
          height={isMobile ? 432 :632}
        />
      </div>
    </div>
  ) : (
    <></>
  );
}

export default ModalStories;