import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import ModalStories from "../ModalStories";
import { CompanyContext } from './../../../contexts/CompanyContext';
import { getHighlights } from './../../../services/highlights/requests';
import { getStoriesImage } from "../../../utils/imagesStories";
import { getSocialLogo } from './../../../services/files/requests';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        // right: '10px',
        // zIndex: '999',
        //color: '#4a4a4a8c',
        color: 'white',
        backgroundColor: 'red',
        borderRadius: '50%',
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        // left: '10px',
        // zIndex: '999',
        color: '#4a4a4a8c',
        backgroundColor: 'red',
        borderRadius: '50%',
      }}
      onClick={onClick}
    />
  );
}

const Stories = () => {

  const {companyData}  = useContext(CompanyContext);
  const [openModalStories, setOpenModalStories] = useState(false);
  const [highlights, setHighlights] = useState([])
  const [highlight, setHighlight] = useState({})
  const [logo, setLogo] = useState("")

  useEffect(() => {
    if(companyData?.id) {
      getStoriesList()
      getLogoStories()
    }
  },[companyData])

  const getStoriesList = async () => {
    const result = await getHighlights({}, companyData?.id)
    if(result.data.metadata.result >= 1){
      setHighlights(result.data.data.filter(item => item.status === 'active'))
      // console.log(result.data.data)
    } else {
      setHighlights([])
    }
  };

  const getLogoStories = async () => {
    const result = await getSocialLogo(companyData.id)
    if (result) {
      if (result.data.metadata.result >= 1) {
        setLogo(result.data.data[0]);
      } else {
        setLogo(null)
      }
    }
  }

  const settings = {
    infinite: highlights.length > 9 ? true : false,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2500,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          infinite: highlights.length > 6 ? true : false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: highlights.length > 3 ? true : false,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: highlights.length > 3 ? true : false,
        },
      },
    ],
  };

  return (
    highlights.length > 0 ?
      <>
        <ModalStories
          open={openModalStories}
          onClose={() => setOpenModalStories(false)}
          stories={highlights}
          company={companyData}
        />
        <section className="gauto-service-area section_40" style={{ paddingBottom: 0}}>
          <div
            style={{
              margin: 'auto',
              maxWidth: 1230,
              paddingTop: 8
            }}
          >
            <Slider {...settings} key={highlights} className="highlights">
              {highlights?.map((story) => (
                <div key={story.id} style={{width: 100}}>
                  <img
                    onClick={() => {
                      setHighlight(story)
                      setOpenModalStories(true)
                    }}
                    style={{
                      borderRadius: '50%',
                      width: 100,
                      height: 100,
                      padding: 2,
                      border: '3px solid red',
                      cursor: 'pointer',
                      margin: 'auto',
                    }}
                    src={getStoriesImage(story, companyData?.logo, logo)}
                    alt={story.title}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </section>
      </>
    : <></>
  )
}

export default Stories;