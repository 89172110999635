import axios from '../axios-instance';
import '../token-interceptor';

export function getPages(params, companyId) {
  return axios.get(`/pages/list-by-companie-id/${companyId}`, {...params});
}

export function getPagesBySlug(slug, params) {
  return axios.get(`/pages/slug/${slug}${params.company ? `?company=${params.company}` : ""}`, {});
}

//Blocks

export function getBlocksByPageSlug(slug, company, name) {
  return axios.get(`/pages/blocks/slug/${slug}?company=${company}&name=${name}`, {});
}