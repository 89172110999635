import React, { Component, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Images
import pic6 from "../../images/about/pic6.jpg";
import { CompanyContext } from '../../contexts/CompanyContext';
import { getBlocksByPageSlug } from '../../services/pages/requests';

// class ChooseUsSection extends Component{
// 	render(){
	const ChooseUsSection = () => {

		const {companyData} = useContext(CompanyContext);
		const [blocks, setBlocks] = useState([]);

    useEffect(() => {
        getBlocksList()
    },[])

    const getBlocksList = async () => {
        const result = await getBlocksByPageSlug('inicio', companyData?.id, 'why_choose_us')
        if(result.data.metadata.result >= 1) {
            setBlocks(result.data.data.filter(item => item.status === 'active'))
        } else {
            setBlocks([])
        }
    }

		return(blocks.length > 0 ?
			<>
				<section className="section-area bg-white section-sp1 why_choose_us">
					{blocks.map(item => (
						<div className="container" dangerouslySetInnerHTML={{__html: item.description}} key={item.id}></div>
					))}
					{/* <div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 mb-30">
								<div className="choose-media">
									<img src={pic6} alt=""/>
								</div>
							</div>
							<div className="col-lg-6 mb-30">
								<div className="heading-bx mb-30">
									<h6 className="title-ext text-primary">WHY PEOPLE CHOOSE US</h6>
									<h2 className="title mb-0">WHY CHOOSE US</h2>
									<p>We offer a full range of garage services to vehicle owners located in Tucson area. All mechanic services are performed by highly qualified mechanics. We can handle any car problem.</p>
								</div>
								<div className="progress-area">
									<div className="progress-box style1 appear">
										<div className="progress-info">
											<h6 className="title">Quality Services</h6>
											<h6 className="progress-value">65%</h6>
										</div>
										<div className="progress">
											<div className="progress-bar bg-primary" style={{width: "65%"}}></div>
										</div>
									</div>
									<div className="progress-box style1 appear">
										<div className="progress-info">
											<h6 className="title">Experienced Technicials</h6>
											<h6 className="progress-value">85%</h6>
										</div>
										<div className="progress">
											<div className="progress-bar bg-primary" style={{width: "85%"}}></div>
										</div>
									</div>
									<div className="progress-box style1 appear">
										<div className="progress-info">
											<h6 className="title">Long Term Warranty</h6>
											<h6 className="progress-value">70%</h6>
										</div>
										<div className="progress">
											<div className="progress-bar bg-primary" style={{width: "70%"}}></div>
										</div>
									</div>
								</div>
								<Link to="/about-us" className="btn btn-primary">READ MORE</Link>
							</div>
						</div>
					</div> */}
				</section>
			</>
			: <></>
		);
	}
// }

export default ChooseUsSection;