import React, {Component, useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

// Elements
import FeatureOneSection from "../elements/feature-bx1";
import ChooseUsSection from "../elements/choose-us";
import ScheduleServiceSection from "../elements/schedule-service";
import PartnerShipsSection from "../elements/partnerships";

// Images
import bnrImg from "../../images/banner/bnr3.jpg";
import { CompanyContext } from '../../contexts/CompanyContext';
import { WhatsAppContext } from '../../contexts/WhatsappContext';
import { LeadContext } from '../../contexts/LeadContext';
import useTraceId from '../../hooks/useTraceId';
import { getPagesBySlug } from '../../services/pages/requests';
import { Helmet } from 'react-helmet';
import { getProdsServs } from '../../services/prod_serv/requests';

// class Services extends Component{
	
// 	render(){
	const Services = () => {

		const {companyData} = useContext(CompanyContext);
		const { changeData } = useContext(WhatsAppContext);
		const [ pageBanner, setPageBanner] = useState("");
		const [ title, setTitle] = useState("");
		const [prodsServs, setProdsServs] = useState([]);
		const { traceData } = useContext(LeadContext);
		const trace = useTraceId();

		const [pageTitle, setPageTitle] = useState("");
		const [lastPageTitle, setLastPageTitle] = useState(document.title);

		useEffect(() => {
			setPageTitle(document.title);
		}, [document.title]);

		useEffect(() => {
			if(companyData.id && pageTitle !== lastPageTitle) {
				if (trace.traceId) {
					trace.setTraceParams({
						...traceData,
						trid: trace.traceId,
						crm_lead_id: trace.leadId,
						dominio: `${window.location.origin}`,
						local: `${window.location.pathname}`,
						title: `${document.title}`,
						tipo: null,
					});
				}
			}
		}, [trace.traceId, trace.leadId, companyData, pageTitle]);

		useEffect(() => {
			if(companyData.id) {

				changeData({
					phone: companyData?.whatsapp_number,
					message: `Olá, ${companyData?.nome}!. Estou acessando seu site, ${window.location.host}, na tela de ${title ? title : "Serviços"}. Alguém poderia me atender?`
				})

				const getPage = async () => {
					// console.log(pathname)
					const pathnameWithoutSlash = window.location.pathname.substring(1);
					const result = await getPagesBySlug(pathnameWithoutSlash, {company: companyData.id})
					// console.log(result)
					if(result?.data?.metadata?.result >= 1) {
						// console.log(result.data)
						setTitle(result?.data?.data[0]?.title)
						setPageBanner(result?.data?.data[0]?.media[0]?.file || "")
					} 
				}
				getPage()
			}
		},[companyData])

		useEffect(() => {
			if(companyData.id) {
				getListProdsServs()
			}
		},[companyData])

		const getListProdsServs = async () => {
			const result = await getProdsServs({}, companyData.id)
			if(result.data.metadata.result >= 1) {
				setProdsServs(result.data.data.data.filter(item => item.status === 'active'))
			} else {
				setProdsServs([])
			}
		}

		return (
			<>
				<Helmet>
					<title>{`${title ? title : "Serviços"}`} </title>
					<meta name="description" content={`${title ? `${title} - ` : 'Serviços - '} ${companyData?.seo_title ? companyData.seo_title : companyData.nome}`}/>
					<meta name="og:description" content={`${title ? `${title} - ` : 'Serviços - '} ${companyData?.seo_title ? companyData.seo_title : companyData.nome}`}/>
					<meta property="og:title" content={`${title ? title : "Serviços"}`}/>
					<meta property="og:site_name" content={`${title ? title : "Serviços"}`}/>
					<meta property="og:url" content={`${window.location.host}${window.location.pathname}`}/>
					<meta property="og:image" content={`${companyData?.logo}`}/>
					<meta name="keywords" content={companyData?.seo_tags}/>
				</Helmet>
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: pageBanner ? `url(${pageBanner})` : ""}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>{title ? title : "Serviços"}</span></h1>
								{/* <nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Services 2</li>
									</ul>
								</nav> */}
							</div>
						</div>
					</div>
					
					<FeatureOneSection services={prodsServs}/>
					
					{/* <ChooseUsSection /> */}
					
					<ScheduleServiceSection />
					
					<PartnerShipsSection />
				
				</div>
				
				
			</>
		);
	}
// }

export default Services;