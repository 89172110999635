import React, { Component, useContext } from 'react';
import { Link } from 'react-router-dom';

// Images
import bg1 from "../../images/background/bg1.png";
import pic1 from "../../images/about/pic1.jpg";
import { CompanyContext } from '../../contexts/CompanyContext';
import formatWhatsNumber from '../../utils/formatWhatsNumber';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';

// class AboutUsOneSection extends Component{
// 	render(){
	const  AboutUsOneSection = () => {

		const {companyData} = useContext(CompanyContext);

		return(
			<>
				
				<section className="section-area section-sp1 bg-white" style={{backgroundImage: "url("+bg1+")", backgroundRepeat:"no-repeat"}}>
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-5 mb-40">
								<div className="heading-bx mb-4">
									<h6 className="title-ext text-primary">Sobre Nós</h6>
									<h2 className="title">{companyData?.nome}</h2>
									{/* <h2 className="title">WE'RE A COMPANY OF TALENTED ENGINEERS &amp; MECHANICS</h2> */}
									<p>{companyData?.description}</p>
								</div>
								{/* <Link to="/about-1" className="btn btn-primary">Ver Mais</Link> */}
							</div>
							<div className="col-lg-7 mb-30">							
								<div className="about-media">
									<div className="media">
										<img src={pic1} alt=""/>
									</div>
									<div className="about-contact bg-primary text-white">
										<h6 className="title-ext text-white">Fale Conosco</h6>
										<h3 className="number mb-15 text-white">
											{companyData?.whatsapp_number ?
												<>
													{formatPhoneNumber(companyData?.whatsapp_number)}
													<i className="fa fa-whatsapp" style={{marginLeft: 8}}></i>
												</>
												: 
												<>
													{formatPhoneNumber(companyData?.phone_main)}
												</>
											}
										</h3>
										<p className="mb-20">Ficaremos felizes em te atender</p>
										<Link 
											rel="noreferrer"
											target="_blank"
											to={companyData?.whatsapp_number ?
												`https://api.whatsapp.com/send?phone=55${formatWhatsNumber(companyData?.whatsapp_number)}&text=Olá, ${companyData?.nome}! Acessei o seu site ${window.location.host} e preciso de ajuda, alguém pode me atender? `
												: companyData?.phone_main ? 
												`tel:${formatWhatsNumber(companyData?.phone_main)}`
												: companyData?.email ? 
												`mailto:${companyData?.email}`
												: "/contato"
											} 
											className="btn btn-light"
										>
											Conversar
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				
			</>
		);
	}
// }

export default AboutUsOneSection;