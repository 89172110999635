import React, {Component, useContext, useEffect, useLayoutEffect, useState} from 'react';
import { Link } from 'react-router-dom';

// Images
import bnrImg from "../../images/banner/bnr5.jpg";
import blogGridPic1 from "../../images/blog/grid/pic1.jpg";
import blogGridPic2 from "../../images/blog/grid/pic2.jpg";
import blogGridPic3 from "../../images/blog/grid/pic3.jpg";
import blogGridPic4 from "../../images/blog/grid/pic4.jpg";
import blogGridPic5 from "../../images/blog/grid/pic5.jpg";
import blogGridPic6 from "../../images/blog/grid/pic6.jpg";
import authorThumbPic1 from "../../images/testimonials/pic1.jpg";
import authorThumbPic2 from "../../images/testimonials/pic2.jpg";
import authorThumbPic3 from "../../images/testimonials/pic3.jpg";
import authorThumbPic4 from "../../images/testimonials/pic4.jpg";
import authorThumbPic5 from "../../images/testimonials/pic5.jpg";
import authorThumbPic6 from "../../images/testimonials/pic6.jpg";
import { Helmet } from 'react-helmet';
import { CompanyContext } from '../../contexts/CompanyContext';
import { LeadContext } from '../../contexts/LeadContext';
import useTraceId from '../../hooks/useTraceId';
import { dateFormatterLong } from '../../utils/convertDates';
import { getNews } from '../../services/news/requests';
import { getPagesBySlug } from '../../services/pages/requests';
import { WhatsAppContext } from './../../contexts/WhatsappContext';

const content = [
	{ 
		thumb: blogGridPic1,
		authorThumb: authorThumbPic1,
		authorName: "Mark John",
		title: "The Number Of Electric Vehicles In Doubled",
		blogText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
	},
	{ 
		thumb: blogGridPic2,
		authorThumb: authorThumbPic2,
		authorName: "Merry Desulva",
		title: "Make Your Auto Clean As Before Like New",
		blogText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
	},
	{ 
		thumb: blogGridPic3,
		authorThumb: authorThumbPic3,
		authorName: "Thomas deo",
		title: "Interior Cleaning with a Steam Generator",
		blogText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
	},
	{ 
		thumb: blogGridPic4,
		authorThumb: authorThumbPic4,
		authorName: "Merry Desulva",
		title: "Types of Portal Sinks and their Advantages",
		blogText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
	},
	{ 
		thumb: blogGridPic5,
		authorThumb: authorThumbPic5,
		authorName: "Thomas deo",
		title: "Excellent Customer Service Car Repair",
		blogText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
	},
	{ 
		thumb: blogGridPic6,
		authorThumb: authorThumbPic6,
		authorName: "Mark John",
		title: "Classification of Car Wash Types by Service Type",
		blogText: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
	},
]

// class BlogGrid extends Component{
	
// 	render(){
	const BlogGrid = () => {

		const [ title, setTitle] = useState("");
		const [ pageBanner, setPageBanner] = useState("");
		const {companyData} = useContext(CompanyContext);
		const { changeData } = useContext(WhatsAppContext);
		const { traceData } = useContext(LeadContext);
		const trace = useTraceId();

		const [pageTitle, setPageTitle] = useState("");
		const [lastPageTitle, setLastPageTitle] = useState(document.title);

		const [news, setNews] = useState([]);

		useEffect(() => {
			setPageTitle(document.title);
		}, [document.title]);

		useEffect(() => {
			if(companyData.id && pageTitle !== lastPageTitle) {
				if (trace.traceId) {
					trace.setTraceParams({
						...traceData,
						trid: trace.traceId,
						crm_lead_id: trace.leadId,
						dominio: `${window.location.origin}`,
						local: `${window.location.pathname}`,
						title: `${document.title}`,
						tipo: null,
					});
				}
			}
		}, [trace.traceId, trace.leadId, companyData, pageTitle]);

		useEffect(() => {
			if(companyData.id) {

				changeData({
					phone: companyData?.whatsapp_number,
					message: `Olá, ${companyData?.nome}!. Estou acessando seu site, ${window.location.host}, na tela de ${title ? title : 'Artigos'}. Alguém poderia me atender?`
				})

				const getPageBanner = async () => {
					// console.log(pathname)
					const pathnameWithoutSlash = window.location.pathname.substring(1);
					const result = await getPagesBySlug(pathnameWithoutSlash, {company: companyData.id})
					// console.log(result)
					if(result?.data?.metadata?.result >= 1) {
						// console.log(result.data)
						setPageBanner(result?.data?.data[0]?.media[0]?.file || "")
						setTitle(result?.data?.data[0]?.title || "")
					} 
				}
				getPageBanner()

				getNewsList()
				
			}
		},[companyData])

		const getNewsList = async () => {
			const result = await getNews({}, companyData.id)
			if(result.data.metadata.result >= 1){
				setNews(result.data.data.filter(item => item.status === 'active'))
			}
		}


		return (
			<>
				<Helmet>
					<title>{`${title ? title : 'Artigos'}`}</title>
					<meta name="description" content={`${title ? title : 'Artigos'} - ${companyData?.seo_title ? companyData.seo_title : companyData.nome}`}/>
					<meta name="og:description" content={`${title ? title : 'Artigos'} -  ${companyData?.seo_title ? companyData.seo_title : companyData.nome}`}/>
					<meta property="og:title" content={`${title ? title : 'Artigos'}`}/>
					<meta property="og:site_name" content={`${title ? title : 'Artigos'}`}/>
					<meta property="og:url" content={`${window.location.host}${window.location.pathname}`}/>
					<meta property="og:image" content={`${companyData?.logo}`}/>
					<meta name="keywords" content={companyData?.seo_tags}/>
				</Helmet>

				<div className="page-content bg-gray">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: pageBanner ? `url(${pageBanner})` : ""}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Artigos</span></h1>
								{/* <nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Blog Grid</li>
									</ul>
								</nav> */}
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp2">
					   <div className="container">
							<div className="row">
								{news.length > 0 ? news.map((item, index) =>(
										<div key={item.id} className="col-xl-4 col-md-6">
											<div className="blog-card style-1 bg-white shadow">
												{item?.media?.length > 0 ?
													<div className="post-media">
														<a href={`/artigo/${item.slug}`}><img src={item.media[0].file} alt="" style={{height: 250}}/></a>
													</div>
												: <></>}
												<div className="post-info">
													<h5 className="post-title"><Link to={`/artigo/${item.slug}`}>{item.title}</Link></h5>
													{/* <div className="post-content">
														<p className="mb-0">{item.blogText}</p>
													</div> */}
													<ul className="post-meta">
														{/* <li className="author"><img src={item.authorThumb} alt=""/>By <Link to="#">{item.authorName}</Link></li> */}
														<li className="date"><Link to={`/artigo/${item.slug}`}>{item.created_at ? dateFormatterLong.format(new Date(item.created_at)) : ""}</Link></li>
													</ul>
												</div>
											</div>
										</div>
									))
								: <span>Nenhum Cadastro encontrado para {title ? title : 'Artigos'}</span>}
							</div>
							{/* <div className="row">
								<div className="col-lg-12">
									<div className="pagination-bx text-center clearfix">
										<ul className="pagination">
											<li className="previous"><Link to="#">Prev</Link></li>
											<li className="active"><Link to="#">1</Link></li>
											<li><Link to="#">2</Link></li>
											<li><Link to="#">3</Link></li>
											<li className="next"><Link to="#">Next</Link></li>
										</ul>
									</div>
								</div>
							</div> */}
						</div>
					</section>
				
				</div>
				
			</>
		);
	}
// }

export default BlogGrid;