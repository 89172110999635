import React, { Component, useContext, useEffect, useState } from 'react';
import formatWhatsNumber from '../../utils/formatWhatsNumber';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { CompanyContext } from '../../contexts/CompanyContext';
import { LeadContext } from '../../contexts/LeadContext';
import useTraceId from '../../hooks/useTraceId';
import { LeadData } from '../../contexts/leadData';
import { phoneMask } from '../../utils/maskForPhone';
import Swal from 'sweetalert2';
import { createLead } from '../../services/lead/requests';

// class ScheduleServiceSection extends Component{
// 	render(){
	const ScheduleServiceSection = () => {

		const {companyData} = useContext(CompanyContext);
		const [loading, setLoading] = useState(false);
		const [data, setData] = useState({nome: "", email: "", celular: ""})
		const trace = useTraceId();
		const { traceData } = useContext(LeadContext);

		useEffect(() => {
			if(localStorage.getItem('___LeadID')){
				setData({nome: localStorage.getItem('___LeadName'), email: localStorage.getItem('___LeadEmail'), celular: localStorage.getItem('___LeadMobile')})
			}
		},[])

		const buildRequestData = () => {
			const url = window.location.href;
	
			const lead = new LeadData({
				...data,
				tipo: 2,
				celular: data.celular ? data.celular.replace(/[^0-9]/g, '') : "",
				cidade: companyData?.id_city,
				id_company: companyData?.id,
				estado: companyData?.id_uf,
				source: 'agendamento',
				dominio: window.location.host,
				local: window.location.pathname,
				referrer: window.location.href,
				agent: navigator.userAgent,
				// ...traceData,
				trace_id: trace.traceId,
				url,
			});
			
			return lead.getData();
		};
	
		const getFirstName = (frase) => {
			// Remova os espaços em branco no início e no final da frase
			frase = frase.trim();
			// Divida a frase em palavras usando o espaço como delimitador
			var palavras = frase.split(" ");
			// Pegue o primeiro elemento do array de palavras
			var primeiraPalavra = palavras[0];
			// Retorne a primeira palavra
			return primeiraPalavra;
		}
	
		const sendLead = async (e) => {
			e.preventDefault();
	
			if(data.nome && data.celular) {
				setLoading(true)
		
				const leadData = buildRequestData();
				// console.log(leadData)
		
				const result = await createLead(leadData);
				const leadId = result?.data?.data?.lead_id || null;
				if (leadId) {
		
					localStorage.setItem('___LeadID', leadId);
					localStorage.setItem('___LeadName', leadData.nome);
					localStorage.setItem('___LeadMobile', leadData.celular);
					localStorage.setItem('___LeadEmail', leadData.email);
		
					Swal.fire({
						icon: 'success',
						title: `${getFirstName(leadData.nome)}, enviamos seu pedido de agendamento! 🚀`,
						text: `Em breve entraremos em contato para confirmação! 🎉🗓`,
					});
	
					setData({...data, cpf: ""})
		
				} else {
					Swal.fire({
						icon: 'error',
						title: result.data.metadata.output.raw,
						text: result.data.metadata.reason,
					});
				}
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Nome e celular não podem ser vazios',
					// text: 'Nome e Celular não podem ser vazios',
				});
			}
			setLoading(false)
		}
		
		return(
			<>
				<section className="section-area bg-primary form-card">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-3 align-self-center">
								<div className="form-head text-white mb-md-30">
									<h3 className="title">Agendamento</h3>
									<h6 className="mb-0"><span className="fw4 mr-1" style={{marginRight: 8}}>ou FALE CONOSCO</span> 
										{companyData?.whatsapp_number ? 
											<a target="_blank" href={`https://api.whatsapp.com/send?phone=55${formatWhatsNumber(companyData?.whatsapp_number)}&text=Olá, ${companyData?.nome}! Acessei o seu site ${window.location.host} e preciso de ajuda, alguém pode me atender? `} rel="noreferrer" className='text-white'>
												{formatPhoneNumber(companyData?.whatsapp_number)}
												{/* <i className="fa fa-whatsapp" style={{marginLeft: 8}}></i> */}
											</a>
											:
											<a target="_blank" href={`tel:${formatWhatsNumber(companyData?.phone_main)}`} rel="noreferrer" className='text-white'>
												{formatPhoneNumber(companyData?.phone_main)}
											</a>
										}
									</h6>
								</div>
							</div>
							<div className="col-lg-9">
								<form onSubmit={(e) => sendLead(e)}>
									<div className="row sp5">
										<div className="col-md-4 col-sm-6">
											<div className="form-group">
												<input type="text" className="form-control" placeholder="ex: Amanda Martins*" value={data.nome} onChange={(e) => setData({...data, nome: e.target.value})}/>
											</div>
										</div>
										<div className="col-md-4 col-sm-6">
											<div className="form-group">
												<input type="text" className="form-control" placeholder="ex: exemplo@email.com" value={data.email} onChange={(e) => setData({...data, email: e.target.value})}/>
											</div>
										</div>
										<div className="col-md-4 col-sm-6">
											<div className="form-group">
												<input type="text" className="form-control" placeholder="ex: (99) 99999-9999*" value={phoneMask(data.celular)} onChange={(e) => setData({...data, celular: e.target.value})}/>
											</div>
										</div>
										{/* <div className="col-md-4 col-sm-6">
											<div className="form-group">
												<select className="form-select form-control">
													<option>Tipo Serviço*</option>
													<option>Oil change</option>
													<option>Break repair</option>
													<option>Car wheels</option>
													<option>General service</option>
													<option>Car batteries</option>
													<option>Car painting</option>
													<option>Wheel change</option>
													<option>Engine repair</option>
												</select>
											</div>
										</div> */}
										{/* <div className="col-md-4 col-sm-6">
											<div className="form-group">
												<input type="text" className="form-control" placeholder="Como podemos ajudar*"/>
											</div>
										</div> */}
										<div className="col-md-12 col-sm-12">
											<div className="form-group">
												<button type="submit" className="btn btn-warning w-100"> 
													{loading ? 
														<div className="spinner-grow" role="status" style={{height: 10, width: 10, marginRight: 8}}></div>
													: <></>}
													{loading ? 'Enviando' : <>
														<i className='fa fa-calendar-check-o' style={{marginRight: 8}}/>Agendar
													</>}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
// }

export default ScheduleServiceSection;